import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useNavigate } from 'react-router-dom';


function BasicExample() {
  const [open, setOpen] = useState()
  const [open1, setOpen1] = useState(false)
  const [itemname, setItemName] = useState()
  const [itemname1, setItemName1] = useState()
  const[toggleopen,setToggleOpen]=useState(false)


  const menudata = [
    {
      "menus": [
        {
          "title": "Home",
          "link": "/"
        },
        {
          "title": "About Us",
          "dropdown": [
            {
              "title": "BSTL Profile",
              "link": "/page/bstlprofile"
            },
            {
              "title": "Management",
              "link": "/management"
            },
            {
              "title": "Vision, Mission, Value",
              "link": "/vision"
            }
          ]
        },
        {
          "title": "Our Business",
          "dropdown": [
            {
              "title": "Energy", "link": "/page/energy",
              "submenu": [
                {
                  "title": "ABB LLC",
                  "link": "/page/ABB_LLC"
                },
                {
                  "title": "Hitachi Energy LLC",
                  "link": "/page/hitachi"
                },
                {
                  "title": "Hyunet Private Ltd",
                  "link": "/page/hyunet"
                },
                {
                  "title": "Green Tech Energy & Water LLC",
                  "link": "/page/green"
                }
              ]
            },
            {
              "title": "Food",
              "link":"/page/food",
              "submenu": [
                {
                  "title": "Homeland Plants LLC",
                  "link": "/page/homeland"
                },
                {
                  "title": "Blue Aqua LLC",
                  "link": "/page/blueaqua"
                },
                {
                  "title": "Blue Aqua, Singapore",
                  "link": "/page/blueaquasinapure"
                }
              ]
            },
            {
              "title": "Healthcare",
              "link":"/page/health",
              "submenu": [
                {
                  "title": "Apollo Hospital Muscat LLC",
                  "link": "/page/apollo"
                },
                {
                  "title": "International Medical Center LLC (Sugar Apollo)",
                  "link": "/page/international"
                },
                {
                  "title": "Apollo Super Speciality Hospital LLC",
                  "link": "/page/super"
                }
              ]
            },
            {
              "title": "Retail and Fashion",
              "link":"/page/fashion",
              "submenu": [
                {
                  "title": "Ash Amaira Retail Private Limited",
                  "link": "/page/retail"
                },
                {
                  "title": "Ash Amaira Accessories Trading LLC",
                  "link": "/page/accessories"
                }
              ]
            },
            {
              "title": "Asset Security & Asset Management",
              "link":"/page/asset",
              "submenu": [
                {
                  "title": "General Dynamics Mission Systems-Gulf LLC",
                  "link": "/page/general"
                },
                {
                  "title": "Gulf Security Services LLC",
                  "link": "/page/gulf"
                },
                {
                  "title": "Bahwan Lamnalco SAOC",
                  "link": "/page/lamnalco"
                }
              ]
            },
            {
              "title": "International Representation",
              "link":"/page/internatinol",
              "submenu": [
                {
                  "title": "Rosenbauer MENA FZE (Austria)",
                  "link": "/page/rosenbauer"
                },
                {
                  "title": "Cantiere Navale Vittoria S.p.A. (Italy)",
                  "link": "/page/cantiere"
                },
                {
                  "title": "Vanderlande Industries B.V. (Netherlands)",
                  "link": "/page/vanderlande"
                },
                {
                  "title": "Loconav Inc. (USA)",
                  "link": "/page/loconav"
                }
              ]
            },
            {
              "title": "Packaging & Logistic",
              "link":"/page/packaging",
              "submenu": [
                {
                  "title": "Al Qantar International SPC",
                  "link": "/page/woodenm"
                }
              ]
            },
            {
              "title": "Real Estate & Projects",
              "link":"/page/realestate",
              "submenu": [
                {
                  "title": "Techno Elevators LLC",
                  "link": "/page/techno"
                },
                {
                  "title": "Intelligent Parking & Elevators LLC",
                  "link": "/page/intelligent"
                },
                {
                  "title": "IPark Robotic Parking & Elevators LLC",
                  "link": "/page/ipark"
                },
                {
                  "title": "North Ocean Industries SPC",
                  "link": "/page/north"
                }
              ]
            },
            {
              "title": "Financial Services",
              "link":"/page/financial",
              "submenu": [
                {
                  "title": "Sadad International LLC",
                  "link": "/page/sadad"
                }
              ]
            }
          ]
        },
        {
          "title": "International",
          "link":"/internationals",
        },
        {
          "title": "Media",
          "dropdown": [
            {
              "title": "Images",
              "link": "/images"
            },
            {
              "title": "Videos",
              "link": "/video"
            }
          ]
        },
        {
          "title": "Contact",
          "link": "/contact"
        }
      ]
    }


  ]

  console.log(itemname1)
  return (
    <Navbar expand="lg" sticky='top' className="bg-body-tertiary">
    
      <Navbar.Brand><Link to='/'><img src='/Asets/new-logo.png' /></Link> </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"  />
        <Navbar.Collapse id="responsive-navbar-nav" show={open} className='navbar-left' >
          <Nav className="navber-nav-menus">
              {menudata.map((item) => {
              return (
                item.menus.map((item) => {
                  return (
                    item.dropdown ?
                      <NavDropdown title={item.title} id="basic-nav-dropdown-1"
                        show={item.title === itemname ? open : null}
                        onMouseOver={() => { setOpen(true); setItemName(item.title) }}
                        onMouseLeave={() => { setOpen(false) }}
                         >
                        {
                          item.dropdown.map((item) => {
                            return (
                              <>
                                {item.submenu ?
                                  <NavDropdown id="basic-nav-dropdown-1"
                                    className='dropmenu-leftside'
                                    show={item.title === itemname1 ? open1 : null}
                                    onMouseOver={() => { setOpen1(true); setItemName1(item.title) }}
                                    onMouseLeave={() => { setOpen1(false) }}
                                    title={ // Title of the nested dropdown, now wrapped with Link
                                      <Link to={item.link} onClick={() => { setOpen(false) }} className='navbar-links-our' style={{ textDecoration: 'none', color: 'inherit' }}>{item.title}</Link>}>
                                    {
                                      item.submenu.map((item) => {
                                        return (
                                          <NavDropdown.Item onClick={() => { setOpen(false) }} as={Link} to={item.link}>{item.title}</NavDropdown.Item>
                                        )
                                      })
                                    }
                                  </NavDropdown>
                                  :
                                  <NavDropdown.Item as={Link} onClick={() => { setOpen(false) }} to={item.link}>{item.title}</NavDropdown.Item>
                                }

                              </>

                            )
                          })
                        }
                      </NavDropdown> :
                      <Nav.Link as={Link} to={item.link} id="basic-nav-dropdown-1" style={{paddingLeft:'35px'}}>{item.title}</Nav.Link>)
                }))
            })}
          </Nav>

        </Navbar.Collapse>
      
    </Navbar>
  );
}

export default BasicExample;
