import React from 'react'
import { Button, Col, Container,Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom';

function ContactBanner() {
  const location = useLocation(); // React Hook
  const contact = useNavigate()
  if(location.pathname!=='/contact'){ 
  return (
    <>
    <Container fluid>
        <Row className='Contact-Banner'>
            <Col lg={7} sm={12} xs={12} md={12}>
             <div>
              <h2 style={{color:'white',fontSize:'24px'}}>CONTACT US TODAY TO KNOW MORE</h2>
              </div>
            </Col>
            <Col lg={5} sm={12} xs={12} md={12}>
              <Button onClick={() => contact('/contact')}>
              <h3 style={{fontSize:'20px'}}>Get In Touch</h3>
              </Button>
            </Col>
        </Row>

    </Container>
      
    </>
  )
}
}

export default ContactBanner
