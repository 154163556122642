import React from 'react'
import { Col, Container, Nav, NavItem, NavLink, Row } from 'react-bootstrap';
import { GrFacebookOption,GrLinkedinOption ,GrInstagram,} from "react-icons/gr";
import{BsFillEnvelopeFill} from "react-icons/bs"



function Header() {
  return (
    <>
     <Container fluid className='Header-Component' >
        <Row style={{width:'100%',height:'100%'}}>
            <Col xl={12} lg={12}  md={6} sm={12} xs={12} className="email"  >
              
             
                <p style={{color:'white',padding:'0px 20px 0px 0px',marginBottom:'0px',fontSize:'14px'}}><BsFillEnvelopeFill />&nbsp;info@bstlglobal.com</p>
                

            </Col>
             
            {/* <Col  xl={6} lg={6} md={6} sm={12} xs={12} className="icons" >
                <Nav>
                <NavLink><GrFacebookOption/></NavLink>
                <NavLink><GrInstagram/></NavLink>
                <NavLink><GrLinkedinOption/></NavLink>
                </Nav>
            </Col> */}
          
        </Row>
      </Container>
      
    </>
  )
}

export default Header
