import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import { NavItem, Dropdown } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import $ from 'jquery';


function BasicExample() {
  const internationals = useNavigate()

  const [open, setOpen] = useState(false)
  const [open1, setOpen1] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [open4, setOpen4] = useState(false)
  const [open5, setOpen5] = useState("")


  const asets = useNavigate()
  // const [open, setOpen] = useState(true);

  const location = useLocation();  // const [opens, setOpens] = useState(true);
  const history = useLocation();


  return (
    <Navbar collapseOnSelect sticky='top' expand="lg" bg="dark-color" >
      <Navbar.Brand><Link to='/'><img src='/Asets/new-logo.png' /></Link> </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className='navbar-left'>
        <Nav className="navber-nav-menus">
          <NavItem > <Nav.Link href="/">Home</Nav.Link></NavItem>

          <NavDropdown title="About Us" id="basic-nav-dropdown-1"
            show={open}
            renderMenuOnMount={true}
            className="smooth-dropdown"
            // onMouseEnter={()=>{setOpen(true)}}
            onMouseLeave={() => setOpen(false)}
            onMouseOver={() => setOpen(true)}


          >
            <NavDropdown.Item href="/" ><Link to="/page/bstlprofile" onClick={() => { setOpen(false) }}>BSTL Profile </Link></NavDropdown.Item>
            <NavDropdown.Item  >  <Link to="/management" onClick={() => { setOpen(false) }}>Management </Link></NavDropdown.Item>
            <NavDropdown.Item ><Link onClick={() => { setOpen(false) }} to="/vision"> Vision , Mission , Value </Link> </NavDropdown.Item>
          </NavDropdown>


          <NavDropdownMenu title="Our Business" id="collasible-nav-dropdown" renderMenuOnMount={true} 
        
        show={open3}
        onMouseLeave={() => setOpen3(false)}
        onMouseOver={() => setOpen3(true)}
        className="smooth-dropdown"

          >
            <div className="dropdown-submenu-container">
              <a class="dropdown-item dropdown-submenu dropdown-toggle"  ><Link to={'/page/energy'} onClick={() => { setOpen5("display-none-novedrop") }}>Energy</Link> </a>
              <div class="dropdown-menu show">
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/ABB_LLC">  ABB LLC </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/hitachi">Hitachi Energy LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/hyunet">Hyunet Private Ltd </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/green">Green Tech Energy & Watter LLC  </Link>
                </a>
                {/* <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                <Link to="/page/bahwan">Bahwan Renewable Energy Company  LLC  </Link>
                </a> */}

              </div>
            </div>

            <div class="dropdown-submenu-container" >
              <a class="dropdown-item dropdown-submenu dropdown-toggle" ><Link to={'/page/food'}>Food</Link> </a>
              <div class="dropdown-menu show">
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/homeland">Homeland Plants LLC </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/blueaqua">Blue Aqua LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/blueaquasinapure">Blue Aqua, Singapore</Link>
                </a>
              </div>
            </div>
            <div class="dropdown-submenu-container">
              <a class="dropdown-item dropdown-submenu dropdown-toggle" ><Link to={'/page/health'}>Healthcare</Link> </a>
              <div class="dropdown-menu show">
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/apollo">Apollo Hospital Muscat LLC </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/international">International Medical Center LLC (Sugar Apollo) </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/super">Apollo Super Speciality Hospital LLC </Link>
                </a>
                {/* <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                <Link to="/page/care">Care Plus LLC </Link>
                </a> */}

              </div>
            </div>

            <div class="dropdown-submenu-container">
              <a class="dropdown-item dropdown-submenu dropdown-toggle" ><Link to={'/page/fashion'}>Retail and Fashion</Link> </a>
              <div class="dropdown-menu show">
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/retail">Ash Amaira Retail Private Limited</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/accessories">Ash Amaira Accessories Trading LLC</Link>
                </a>
              </div>
            </div>

            <div class="dropdown-submenu-container">
              <a class="dropdown-item dropdown-submenu dropdown-toggle" ><Link to={'/page/asset'}>Asset Security & Asset Management</Link> </a>
              <div class="dropdown-menu show">
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/general">General Dynamics Mission Systems-Gulf LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/gulf">Gulf Security Services LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/lamnalco">Bahwan Lamnalco SAOC</Link>
                </a>

              </div>
            </div>
            <div class="dropdown-submenu-container">
              <a class="dropdown-item dropdown-submenu dropdown-toggle" ><Link to={'/page/internatinol'}>International Representation</Link> </a>
              <div class="dropdown-menu show">

                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/rosenbauer">Rosenbauer MENA FZE (Austria)</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/cantiere">Cantiere Navale Vittoria S.p.A. (Italy)</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/vanderlande">Vanderlande Industries B.V. (Netherlands)</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/loconav">Loconav Inc. (USA)</Link>
                </a>

              </div>
            </div>
            <div class="dropdown-submenu-container">
              <a class="dropdown-item dropdown-submenu dropdown-toggle" ><Link to={'/page/packaging'}>Packaging & Logistic </Link> </a>
              <div class="dropdown-menu show">
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/woodenm">Al Qantar International spc</Link>
                </a>
                {/* <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                <Link to="/page/woodenr">PalletBiz</Link>
                </a> */}
              </div>
            </div>

            <div class="dropdown-submenu-container">
              <a class="dropdown-item dropdown-submenu dropdown-toggle" ><Link to={'/page/realestate'}>Real Estate & Projects </Link> </a>
              <div class="dropdown-menu show">

                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/techno">Techno  Elevators LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/intelligent">Intelligent Parking  & Elevators LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/ipark">IPark Robotic Parking & Elevators LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/north">North Ocean Industries SPC</Link>
                </a>

              </div>
            </div>

            <div class="dropdown-submenu-container">
              <a class="dropdown-item dropdown-submenu dropdown-toggle" ><Link >Financial Services</Link> </a>
              <div class="dropdown-menu show">
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/sadad">Sadad International LLC </Link>
                </a>

              </div>
            </div>





          </NavDropdownMenu>




















          <NavItem><Nav.Link onClick={() => internationals('internationals')}> International</Nav.Link></NavItem>
          <NavDropdown title="Media"
            renderMenuOnMount={true}>
            <NavDropdown.Item><Link to="/images">Images</Link></NavDropdown.Item>
            <NavDropdown.Item><Link to="/video">Videos</Link></NavDropdown.Item>
            {/* <NavDropdown.Item><Link to="/news">News</Link></NavDropdown.Item> */}
          </NavDropdown>
          <NavItem> <Nav.Link href='/contact'>Contact</Nav.Link></NavItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default BasicExample;