import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Card, Carousel, Col, Container, Row } from 'react-bootstrap';
import { AiFillDollarCircle } from 'react-icons/ai';
import { FaAlignRight, FaArrowCircleRight, FaArrowRight, FaChevronCircleRight, FaChevronRight, FaShopify } from 'react-icons/fa';
import { GiBoxUnpacking, GiHealingShield, GiWireframeGlobe } from 'react-icons/gi';
import { MdApartment } from 'react-icons/md';
import { SiSpringsecurity } from 'react-icons/si';
import { Link } from 'react-router-dom';
import ContactBanner from './ContactBanner';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import { Modal } from 'react-bootstrap'; // Assuming you are using Bootstrap for the modal
import CardFlip from '../Flipcard';


function Homepage() {
  const ourbusines = [
    { image: <img src='Asets/Homepage/MicrosoftTeams-image (19).png' width={'60px'} height={'60px'} />, title: "Energy ", link: "/page/energy" },

    { image: <img src='Asets/Homepage/MicrosoftTeams-image (50).png' width={'55px'} height={'65px'} style={{ fontWeight: '900' }} />, title: "Food", link: "/page/food" },
    { image: <GiHealingShield size="60px" className='icon-color' />, title: "Healthcare ", link: "/page/health" },
    { image: <FaShopify size="60px" className='icon-color' />, title: "Fashion & Retail", link: "/page/fashion" },
    { image: <SiSpringsecurity size="55px" className='icon-color' />, title: "Asset Security & Asset Management", link: "/page/asset" },
    { image: <GiBoxUnpacking size="60px" className='icon-color' />, title: "Packaging & Logistics", link: "/page/packaging" },
    { image: <MdApartment size="60px" className='icon-color' />, title: "Real Estate & Projects", link: "/page/realestate" },
    { image: <AiFillDollarCircle size='60px' className='icon-color' />, title: "Financial Services", link: "/page/financial" },
    { image: <GiWireframeGlobe size='60px' className='icon-color' />, title: "International Representation", link: "/page/internatinol" },

  ]
  const aboutimage = [
    { img: "/Asets/New-home-about/Artboard 1.png" },
    { img: "/Asets/New-home-about/Artboard 2.png" },
    { img: "/Asets/New-home-about/Artboard 3.png" },
    { img: "/Asets/New-home-about/Artboard 4.png" },
    { img: "/Asets/New-home-about/Artboard 5.png" },
    { img: "/Asets/New-home-about/Artboard 6.png" },

  ]



  const aboutimages = [
    { img: "https://admin.bstlglobal.com/storage/images/banners/2023-05-01-644f5c7a31dc4.png" },
    { img: "https://admin.bstlglobal.com/storage/images/banners/2024-01-18-65a8df2435636.png" },
    { img: "https://admin.bstlglobal.com/storage/images/banners/2024-01-18-65a8dd38a8e27.png" },
    { img: "https://admin.bstlglobal.com/storage/images/banners/2023-05-01-644f5c6a8f28f.png" },
    { img: "https://admin.bstlglobal.com/storage/images/banners/2023-05-03-645205dd17b23.png" },
    { img: "https://admin.bstlglobal.com/storage/images/banners/2024-01-18-65a8df18a0464.png" },

  ]
  const [modalShow, setModalShow] = useState(false);
  const [indexvalue, setIndexValue] = useState()

  const handleModalClose = () => {
    setModalShow(false);
  };
  const [images, setImages] = useState();
  useEffect(() => {
    axios.get('https://admin.bstlglobal.com/api/v1/banners')
      .then(response => setImages(response.data))
      .catch(error => console.log(error))
  }, [0])


  return (
    <>
      <Helmet>
        <title>Bstlglobal-Home</title>
        <meta name="description" content="Stay informed about the latest trends and developments in healthcare, aquaponics, retail, renewable energy, packaging & logistics, fashion, real estate, construction, elevators, parking solutions, and security services through our comprehensive website." />
        <meta name="keywords" content="Healthcare,Medicine,Hospital,Pharmacy,cure,surgeon,diabetic care,surgery,obstetrics,gynaecology,cardiology,ENT,spine,Edoscopic,
pediatrics,pathology,ophthalmology,endocrinology,psychology,dermatology,hepatobillary,physiotherapy,dental care,orthopedics,sports,
medicine,emergency,radiology,urology,Aquaponic,Salad leaves,baby leaves,baby bok choy,baby chard,baby kale,baby spinach,fish,asian seabass,
tilapia,leafy greens,butterhead lettuce,curly kale leaf,green lollo,red lollo,spinach,swiss chard,toscana kale,vegetables,beans,cherry tomatoes,
cucumber,green zucchini,roma tomatoes,vine tomatoes,Energy,Solar Energy,Renewable Energy,Solar Panel,power,solar,solar cell,bank of batteries,
panel grid,solar grid,energy conversion,watts,Asset Security,Asset Management,Security,Camera,Packaging,Logistic,Techno Elevators,Parking Solution,
tower parking,basement cart parking,puzzle parking,stacker parking,Retail,ash amaira,shoes,hgandbags,purse,accessories,bridal,scarv" />
      </Helmet>



      <Carousel indicators={false} controls={true}>
        {
          images && images.map((item, index) => {
            return (
              <Carousel.Item key={index} controls >
                <img src={"https://admin.bstlglobal.com/storage/images/banners/" + item.image} className="d-block w-100" />
              </Carousel.Item>
            )
          })
        }
      </Carousel>

      {/* <Container fluid className='home-about'>
  <Row>
  <h3>Bahwan Services & Trading LLC</h3>


    <Col lg={6} className='mt-5 ' style={{paddingLeft:'0px'}}>
      <div className='class-color'>
      <p>Bahwan Services and Trading (BST) is Limited Liability Company from Sultanate of Oman, and is a global, diversified organization of 30 major businesses operating in GCC and Asia as Limited Liability Companies, Joint Ventures or Representations of internationally reputed European and American brands, wholly owned subsidiaries, and a few Start-Ups. BST delivers end-to-end solutions in 8 business segments, namely</p>
  
<p>BST is currently working on agenda of business diversification, and towards the fulfilment of Oman’s Vision 2030 with focus areas as generate employment, bring new technologies and skill development.


</p>

  </div>
    </Col>
    <Col lg={6} className='mt-5'  style={{paddingLeft:'20px'}}>
      <div>
      <Row>
        { aboutimage.map((items,index)=>{
          return(
        
        <Col lg={4} style={{ margin: '0px', padding: '0px'}}>
  <div
    style={{
      overflow: 'hidden',
      position: 'relative',
    }}
  >
    <img
      src={items.img}
      className='aboutimg'
      width={'100%'}
      height={'190px'}
      style={{
        objectFit: 'cover',
        transition: 'transform 0.3s', 
      }}
      onMouseOver={(e) => {
        e.target.style.transform = 'scale(1.2)'; 
      }}
      onMouseOut={(e) => {
        e.target.style.transform = 'scale(1)'; 
      }}
      onClick={()=>{  setModalShow(true);setIndexValue(index)}}


    />
  </div>
</Col>


          )
        })
        
}
      </Row>
      </div>

    
    </Col>
  </Row>
  <Modal show={modalShow} onHide={handleModalClose}>
        Modal content goes here
       
        {aboutimages.map((items,index)=>{
          return(
            indexvalue === index ?

        <Modal.Body style={{height:'350px'}}>
        
          <img src={items.img} alt="Zoomed Image" style={{ width: '100%',height:'100%' ,objectFit:'cover   '}} />
          Add more details or content as needed
         
        </Modal.Body>
        :null
        )
      })}
      </Modal>

    </Container> */}




      <Container fluid className='ourBusiness ' >
        <Row >
          <div>
            <h1 className='text-center  mt-5'>Our Business</h1>
          </div>
          {ourbusines.map((item, key) => {
            return (

              <Col lg={3} sm={6} md={6} xl={3} xxl={3} key={key} className='our-business-col ' >

                <Link to={item.link} className="link">
                  <div>
                    <Card className='our-business rain-animation' >

                      <div className="layer">


                      </div>
                      <div className="content">
                        <div className='' style={{ height: '75px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <span className='icon-color ' > {item.image}</span>
                        </div>
                        <div style={{ height: '40px' }}>


                          {
                            item.title == "Asset Security & Asset Management" ? <h6 className='text-center card-texts' > Asset Security  <br></br>  Asset Management </h6> :
                              <h6 >{item.title}</h6>

                                &&
                                item.title == "Energy Security & Energy Management" ? <h6 className='text-center card-texts' > Energy Security & Energy  <br></br>  Management </h6> :
                                <h6 >{item.title}</h6>

                                  &&

                                  item.title == "Real Estate and Construction Projects" ? <h6 className='text-center card-texts' > Real Estate and  <br></br>  Construction Projects </h6> :
                                  <h6 >{item.title}</h6>

                          }

                        </div>
                      </div>
                    </Card>
                    <div className='icon-right-arrow'>
                      <div className='icon-path-div'>
                        <FaChevronRight fontSize={'10px'} />
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
            )
          })}
          <div className='mb-5'></div>
        </Row>
      </Container>

      {/* <CardFlip /> */}

    </>
  )
}

export default Homepage
