import React from 'react'
import { Container,Row,Col, Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import {AiOutlineMinus} from "react-icons/ai"

function Management() {

  return (
<>
    {/* <Helmet>
    <title>Management</title>
        <meta name="description" content="Description of your page" />
        <meta name="keywords" content="managebstl" />
        <link rel="canonical" href="http://localhost:3000/management"></link>
    </Helmet> */}

      <Container  fluid  className='management'>
        <Row style={{marginTop:'5%',marginBottom:'5%'}} className='management-row'> 
          
          <Col lg={6}  xs={12} sm={12} md={6} className='Management-card-col' style={{width:'300px',marginLeft:'26%'}}>
          
          <Card className='card testimonial-card' >
            <div className='card-up aqua-gradient'> </div>
            <div className="avatar mx-auto white">
        <img src="Asets/seikh abdullah.png" className="rounded-circle img-fluid"
          alt="woman avatar" />
         

      </div>
           
      <div style={{minHeight:'140px',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div>
            <h6 style={{fontSize:'14px'}}>Sheikh Abdullah Bahwan </h6>
          <p className='text-center'> <AiOutlineMinus /></p> 
            <h6 className='text-center' style={{fontSize:'14px'}}>Executive Director</h6>
            </div>
            </div>
           </Card>
          </Col>
          <Col lg={6} xs={12} sm={12} md={6}   className='Management-card-2-col-2' style={{width:'300px'}}>
          <Card className='card testimonial-card'>
            <div className='card-up aqua-gradient'> </div>
            <div className="avatar mx-auto white">
        <img src="Asets/sheikh sultan.png" className="rounded-circle img-fluid" alt="woman avatar" />
        
      </div>
           
      <div style={{minHeight:'140px',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div>
            <h6 style={{fontSize:'14px'}}>Sheikh Sultan Bahwan </h6>
            <p className='text-center'> <AiOutlineMinus /></p> 
            <h6 className='text-center' style={{fontSize:'14px'}}>Director</h6>
            </div>
            </div>
           </Card>
          </Col>
        </Row>
      </Container>
      </>  
  )
}

export default Management
