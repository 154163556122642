import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Carousel, Tabs, Tab, Col, Row, Container } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import { Search } from 'react-bootstrap-icons';
import { Spinner } from 'react-bootstrap';

function Images() {

    const [category, setCategory] = useState()
    const [imagesAll, setImagesAll] = useState()
    const [show, setShow] = useState(false);
    const [activeItem, setActiveItem] = useState(1);
    const [loading, setLoading] = useState(false)
    const handleClose = () => setShow(false);


    const handleShow = (id) => {
        console.log("clicked")
        setShow(true)
        setActiveItem(id)
    };



    useEffect(() => {
        getImages("All")
        axios.get('https://admin.bstlglobal.com/api/v1/categories_list')
            .then(res => { setCategory(res.data); setLoading(true) })
    }, [0])

    const getImages = ((cat) => {
        console.log("function called")
        axios.get('https://admin.bstlglobal.com/api/v1/media_by_images/' + cat)
            .then(res => setImagesAll(res.data))
    })


    return (
        <>
            {loading ?
                <Container fluid>
                    <Row className='Feaild'>
                        <Col lg={2} md={3} xs={12} sm={12} onClick={() => getImages('All')}>
                            <p className='Feaild-p'>Show All</p>
                        </Col>
                        {category &&
                            category.map((element, index) => {
                                return (

                                    <Col lg={2} md={3} xs={12} sm={12} onClick={() => getImages(element.name)}>
                                        <p className='Feaild-p'>{element.name}</p>
                                    </Col>

                                )
                            })
                        }
                    </Row>

                    <Row className='images-row mb-5'>
                        {
                            imagesAll && imagesAll.map((im, index) => {
                                return (
                                    <Col lg={3}>
                                        <figure className="hover-img">
                                            <img src={"https://admin.bstlglobal.com/" + im.image} />
                                            <figcaption>
                                                <Search color="white" onClick={() => handleShow(im.id)} />
                                            </figcaption>
                                        </figure>
                                    </Col>
                                )
                            })

                        }
                    </Row>




                    <Modal show={show} onHide={handleClose} closeButton >
                        <Modal.Body width="100%">
                            <Col>
                                <Carousel >
                                    {imagesAll && imagesAll.map((img) => {
                                        return (
                                            <Carousel.Item className={activeItem == img.id ? 'active' : ''} >

                                                <img src={"https://admin.bstlglobal.com/" + img.image} style={{ width: "100%" }} />


                                            </Carousel.Item>

                                        )
                                    })}

                                </Carousel>
                            </Col>
                        </Modal.Body>



                    </Modal>



                </Container>
                : <div className="text-center mt-5 mb-5">
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>}
        </>
    )
}

export default Images
